<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    <div v-show="!isSpinner">
      <user-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        @refetch-data="refetchData"
      />

      <!-- Filters -->
      <!-- <users-list-filters
        :role-filter.sync="roleFilter"
        :plan-filter.sync="planFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :status-options="statusOptions"
      /> -->

      <!-- Table Container Card -->
      <b-card
        title="Filters"
      >
        <b-row class="tableFilters">
          <b-col md="2">
            <b-form-group label="Intake">
              <b-form-select
                v-model="intake"
                :options="intakeOptions"
                placeholder="All"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Year">
              <b-form-select
                v-model="yearFilter"
                :options="yearOptions"
                placeholder="All"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"            
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  @input="handleSearch"
                />
                <!-- <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button> -->
              </div>
            </b-col>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <!-- <label>entries</label> -->
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative student_inquirytable_wrapper"
          responsive
          :fields="tableColumns"
          :current-page="1"
          :per-page="perPage"
          v-model='docCategoryTable'
          :items="StudentInquiry"
          :busy="isFetching"
          primary-key="id"
          no-local-sorting
          :show-empty="!isSpinner"
          hover
          empty-text="No matching records found"
          @row-clicked="handleRowClick"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          @sort-changed="handleSortChange"
        >
          <!-- Column: No. -->
          <template #cell(no)="data">
            <div>
                <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
            </div>
          </template>

          <!-- Column: Phone no -->
          <template #cell(phone_no)="data">
            <div style="white-space: nowrap">
              {{data.item.phone_no}}
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div style="white-space: nowrap" class="top_btn_right" v-if="$route.meta.action=='write' || $route.meta.action==undefined">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-icon"
                style="margin-right:10px;background-color:#0d4ea0!important;border-color:#0d4ea0!important"
                @click.prevent="selectedUser=data"
                v-b-modal.modal-create-account
              >
              <!-- {{data}} -->
                <!-- <span v-if="isSpinner">
                  <b-spinner small/>
                  <span  class="sr-only">Loading...</span>
                </span> -->
                <!-- <span v-else>Save</span> -->

                <span>Create Account</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-dark"
                class="btn-icon"
                style="color:#0d4ea0;border-color:#cbcbcb!important"
                v-if="!data.item.isDeleted"
                v-b-modal.modal-close-inquiry
                @click.prevent="selectedUser=data"
              >
                <span>Close Inquiry</span>
              </b-button>
            </div>
            <div v-else>
              --
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                @change="handlePageChange"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <!--Create Account Modal -->
      <b-modal
        id="modal-create-account"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @cancel="selectedUser=''"
        @ok="()=>{$bvModal.show('modal-select-details')}"
      >
      <div class="create_account_img">
        <img src="@/assets/images/erflog/Create_Account.png" alt="student">
      </div>
        <div style="text-align: center;" v-if="selectedUser!=''">
          <label class="s_modal_title">Create Account</label>
          <p>Are you sure you want to create account for <span>{{selectedUser.item.student_name}} ?</span></p>
        </div>
      </b-modal>

      <!--Select Details -->
      <b-modal
        id="modal-select-details"
        cancel-variant="outline-secondary"
        ok-title="Save & Invite"
        cancel-title="Cancel"
        centered
        title="Select Details"
        :cancel-disabled="creatingAccount"
        :ok-disabled="creatingAccount"
        no-close-on-backdrop
        @hidden="selectedUser='',documentModule=null"
        @ok="(e)=>{e.preventDefault(); createAccount(e);}"
      >
        <b-spinner v-if="creatingAccount" variant="primary" style="position:absolute;left:50%;z-index:999;width:50px;height:50px;"/>
        <div>
          <validation-observer ref="personalInfoForm">
          <!-- form -->
          <b-form @submit.stop.prevent="createAccount">
            <b-row>
              <!-- Representative -->
              <b-col md="6">
                  <b-form-group label="Representative">
                  <validation-provider
                      #default="{ errors }"
                      name="representative"
                      rules=""
                  >
                      <b-form-select
                          v-model="representative"
                          :options="representativeOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Select Document Module -->
              <b-col md="6">
                  <b-form-group label="Select Document Module">
                  <validation-provider
                      #default="{ errors }"
                      name="document module"
                      rules="required"
                  >
                      <v-select
                        v-model="documentModule"
                        label="title"
                        multiple
                        :options="documentModuleOptions"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
          </b-form>
          </validation-observer>
        </div>
      </b-modal>

      <!--Close Inquiry Modal -->
      <b-modal
        id="modal-close-inquiry"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedUser=''"
        @ok="closeInquiryFunction(selectedUser)"
      >
      <div class="create_account_img">
        <img src="@/assets/images/erflog/DeletePopup.png" alt="">
      </div>
        <div style="text-align: center;" v-if="selectedUser!=''">
          <label class="s_modal_title">Close Inquiry</label>
          <p>Are you sure you want to close this query for {{selectedUser.item.student_name}} ?</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormGroup,BFormRadio,BFormDatepicker,
  BForm,BFormTextarea,BFormSelect,BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useStudentInquiryList'
import userStoreModule from '../userStoreModule'
import UsersListFilters from './UsersListFilters.vue'
import UserListAddNew from './UserListAddNew.vue'

//vee-validator
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required, email,max,max_value,integer,numeric,min,min_value,alpha_spaces,double } from 'vee-validate/dist/rules';
extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)

import firebase from '@/utils/firebaseInit'
import firebaseTimeStamp from 'firebase'
import { dbCollections } from '@/utils/firebaseCollection'
import { ValidationFunction } from '@/utils/globalValidations.js';

const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    UsersListFilters,
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    BFormRadio,
    BFormDatepicker,
    BFormTextarea,
    BFormSelect,
    vSelect,
    BSpinner,
  },
  data(){
    return{
      selectedUser:"",
      representative:null,
      representativeOptions: [{value:null,text:'Select',disabled:true}],
      documentModule:null,
      documentModuleOptions: [],

      creatingAccount:false,
      currenUserObject:{},
      intakeOptions:[
        {value:null,text:'All'},
        'WS',
        'SS'
      ],
      yearOptions:[{value:null,text:'All'}]
    }
  },
  created() {
    var self = this
    for (let i = 0; i <= 4; i++)
    {
      self.yearOptions.push(new Date().getFullYear()+i)
    }

    self.$root.$on('showDeletedInquiries',()=>{
      self.showDeleted=true
    })
    self.$root.$on('hideDeletedInquiries',()=>{
      self.showDeleted=false
    })
    if ('Notification' in window) {
      if (Notification.permission === 'denied' || Notification.permission == 'default') {
        self.$root.$emit('showToastMessage','To receive notifications, please allow Notification permission in site settings and reload page again.','warning')
      } else {
        Notification.requestPermission()
          .then(permission => {
            if (permission === 'granted') {
              console.info('Notification permission has been granted.');
            } else {
              console.info('Notification permission has been denied.');
            }
          })
          .catch(error => {
            console.error('Error occurred while requesting notification permission:', error);
          });
      }
    } else {
      self.$root.$emit('showToastMessage','To receive notifications, please allow Notification permission in site settings and reload page again.','warning')
    }

    // db
    //   .collection(dbCollections.INQUIRY)
    //   .where('isDeleted','==',true)
    //   .get()
    //   .then((querySnapshot)=>{
    //     querySnapshot.forEach(doc=>{
    //       db
    //         .collection(dbCollections.INQUIRY)
    //         .doc(doc.id)
    //         .update({
    //           isDeleted:false
    //         })
    //         .then(()=>{
    //           console.log("Updated")
    //         })
    //         .catch(error=>{
    //           console.log("error",error)
    //         })
    //     })
    //   })
    //   .catch(error=>{
    //     console.log(error)
    //   })
    let usersId = firebase.auth()?.currentUser?.uid ? firebase.auth().currentUser.uid : JSON.parse(localStorage.getItem('currentUser')).id;

    db
      .collection(dbCollections.USERS)
      .orderBy('createdAt')
      .get()
      .then((querySnapshot)=>{
        let representativeArray = [];
        let employeeArry = [];
        querySnapshot.forEach(doc=>{
          if(!doc.data().isDeleted && doc.data().id == usersId){
            self.currenUserObject = doc.data()
          }
          if(!doc.data().isDeleted && doc.data().role == 'employee') {
            if(doc.data().representative){
              employeeArry.push({id:doc.data().id,rId:doc.data().representative.id})
            } else {
              employeeArry.push({id:doc.data().id})
            }
          }
        })
        function findRelatedRIds(id) {
            const relatedRIds = [];
            relatedRIds.push(id)
            const visitedIds = new Set();

            function traverseRelations(currentId) {
                visitedIds.add(currentId);
                
                for (const relation of employeeArry) {
                    if (relation.rId === currentId && !visitedIds.has(relation.id)) {
                        relatedRIds.push(relation.id);
                        traverseRelations(relation.id);
                    }
                }
            }
            
            traverseRelations(id);
            
            return relatedRIds;
        }
        let id = firebase.auth().currentUser.uid
        representativeArray = findRelatedRIds(id)
        // employeeArry.forEach((item)=>{
        //   if(representativeArray.length === 0) {
        //     if(item.id == firebase.auth().currentUser.uid){
        //       representativeArray.push(firebase.auth().currentUser.uid)
        //     }
        //     if(item.rId){
        //       if(item.rId == firebase.auth().currentUser.uid){
        //         representativeArray.push(item.id)
        //       }
        //     }
        //   } else {
        //     if(item.rId){
        //       if(representativeArray.includes(item.rId)){
        //         representativeArray.push(item.id)
        //       }
        //     }
        //   }
        // })
        querySnapshot.forEach(doc=>{
          if(!doc.data().isDeleted && doc.data().role=='employee' && (self.currenUserObject.role == 'employee' && representativeArray.includes(doc.data().id) || self.currenUserObject.role == 'admin') )
          {
            self.representativeOptions.push({value:{name:doc.data().name,eid:doc.data().employeeId,id:doc.data().id,phoneNo:doc.data().personalPhoneNo},text:doc.data().name})
          }
        })
      })

    //Get Document Modules
    db
      .collection(dbCollections.DOCUMENTCATEGORY)
      .orderBy('createdAt')
      .get()
      .then((querySnapshot)=>{
          querySnapshot.forEach(doc=>{
            if(!doc.data().isDeleted)
            {
              self.documentModuleOptions.push({title:doc.data().categoryName,id:doc.data().id})
            }
          })
      })
      .catch(error=>{
          console.log(error)
      })
  },
  mounted() {
    var self = this
    self.$root.$emit('showDeletedInquiries',false)
    self.showDeleted = false
    self.fetchStudentsFromTypesense(true,null,()=>{})
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      closeInquiry,

      refreshUsers,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      isSpinner,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      showDeleted,
      intake,
      yearFilter,
      fetchStudentInquiry,
      filteredInquiryList,
      isFetching,
      docCategoryTable,
      fetchMoreDataStop,
      nextDisabled,
      StudentInquiry,
      fetchStudentsFromTypesense,
      handlePageChange,
      handleSearch,
      handleSortChange
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      closeInquiry,

      refreshUsers,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      isSpinner,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      showDeleted,
      intake,
      yearFilter,
      fetchStudentInquiry,
      filteredInquiryList,
      isFetching,
      docCategoryTable,
      fetchMoreDataStop,
      nextDisabled,
      StudentInquiry,
      fetchStudentsFromTypesense,
      handlePageChange,
      handleSearch,
      handleSortChange
    }
  },
  methods:{
    handleRowClick(data)
    {
      //Show User Details
      this.$router.push({name:'student-inquiry-information',params:{name:data.student_name,id:data.id}})
    },
    isOnlyDecimal(event) {
      ValidationFunction.OnlyFloatWithNumber(event);
    },
    isOnlyInteger(event) {
      ValidationFunction.OnlyIntegerNumber(event);
    },
    getIntakeNo(cb)
    {
      var self = this
      db
        .collection(dbCollections.USERS)
        .orderBy('createdAt')
        .get()
        .then((querySnapshot)=>{
            var newIntakeNo=0
            if(!querySnapshot.empty)
            {
                querySnapshot.forEach(doc=>{
                  if(!doc.data().isDeleted && doc.data().role=="student")
                  {
                      var uniqueId = doc.data().uniqueId
                      if(uniqueId>=newIntakeNo)
                      {
                        newIntakeNo = parseInt(uniqueId)+1
                      }
                      var zeroes = 6-newIntakeNo.toString().length

                      for (let i = 0; i < zeroes; i++) {
                        newIntakeNo='0'+newIntakeNo
                      }
                  }
                })
                if(newIntakeNo==0)
                {
                  newIntakeNo='000001'
                }
            }
            else
            {
              newIntakeNo='000001'
            }
            cb(newIntakeNo)
        })
    },
    createAccount(e)
    {
      var self = this

      self.creatingAccount=true
      var currentUsers = JSON.parse(localStorage.getItem('currentUser'));

      self.$refs.personalInfoForm.validate().then(success=>{
        if(success)
        {
          var index = (self.currentPage-1) * self.perPage + self.selectedUser.index + 1

          self.getIntakeNo(uniqueId=>{
            var userData = {...self.selectedUser.item.docData,representative:self.representative,documentModule:self.documentModule,uniqueId:uniqueId}
            self.$axios.post(dbCollections.APIURL+'createNewStudent',{email:userData.email,name:userData.name})
            .then((resp)=>{
              if(resp.data.status==200)
              {
                db
                  .collection('users')
                  .doc(resp.data.data.uid)
                  .set({
                    'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    'docFiles':userData.docFiles||[],
                    'imageUrl':userData.imageUrl,
                    'imageName':userData.imageName,
                    'firstName':userData.firstName,
                    'lastName':userData.lastName,
                    'name':userData.name,

                    'intake':userData.intake,
                    'year':userData.year,

                    'email':userData.email,
                    'mobileNumber':userData.mobileNumber,
                    'dob':userData.dob,
                    'gender':userData.gender,
                    'addressLine1':userData.addressLine1,
                    'addressLine2':userData.addressLine2,
                    'country':userData.country,
                    'state':userData.state,
                    'city':userData.city,
                    'pincode':userData.pincode,
                    'educationDetails':userData.educationDetails,
                    'language':userData.language,
                    'germanLanguage':userData.germanLanguage,
                    'exams':userData.exams,
                    'fieldOfInterests':userData.fieldOfInterests,
                    'role':'student',
                    'representative':userData.representative||'',
                    'documentModule':userData.documentModule,
                    'status':userData.status,
                    'uniqueId':userData.uniqueId,
                    'isDeleted':false,
                    'id':resp.data.data.uid,

                    'areaOfInterest':userData.areaOfInterest||'',

                    'isActive':true,
                    'countryPhoneCode': userData.countryPhoneCode ? userData.countryPhoneCode : { ru:"Индия",lt:"Indija",tr:"Hindistan", en: 'India',flag: '🇮🇳',code: 'IN', dialCode: '+91', mask: '99999 99999' },
                  })
                  .then(()=>{
                    let notificationObj = {
                        title: `Student Created`,
                        type: 'studentCreatedFromInquiry',
                        userId: resp.data.data.uid,
                        currentUser:firebase.auth().currentUser.uid
                    }
                    if(userData.representative) {
                        notificationObj.message = `Your profile has been created by ${currentUsers.fullName} under ${userData.representative.name}`;
                    } else {
                        notificationObj.message = `Your profile has been created by ${currentUsers.fullName}`;
                    }
                    this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                    }).catch((error)=>{
                        console.error(error);
                    })
                    db
                      .collection(dbCollections.INQUIRY)
                      .doc(self.selectedUser.item.docData.id)
                      .update({
                        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                        isDeleted:false,
                        accountCreated:true
                      })
                      .then(()=>{
                        self.refreshUsers(self.selectedUser,res=>{
                          self.totalUsers = self.totalUsers - 1;
                          self.fetchStudentsFromTypesense(false,{limit:1,pageNumber:self.currentPage+1},()=>self.refetchData());
                          self.selectedUser=''
                        })
                        self.representative=null
                        self.documentModule=null
                        self.$bvModal.hide('modal-select-details')
                        self.$root.$emit('showToastMessage','Account created successfully.','success')
                        self.creatingAccount=false
                      })
                      .catch(error=>{
                        self.$root.$emit('showToastMessage',error.message,'danger')
                        console.log(error)
                        self.creatingAccount=false
                      })
                      self.payments=[{
                          paymentReceivedDate:'',
                          modeOfPayment: 'Cash',
                          bankName: '',
                          chequeNo:'',
                          remark:'',
                          rupees: ''
                      }]
                  })
                  .catch(error=>{
                    self.$root.$emit('showToastMessage',error.message,'danger')
                    self.creatingAccount=false
                    console.log(error)
                  })
              }
              else
              {
                self.$root.$emit('showToastMessage',resp.data.msg,'danger')
                self.selectedUser=''
                self.payments=[{
                  paymentReceivedDate:'',
                  modeOfPayment: 'Cash',
                  bankName: '',
                  chequeNo:'',
                  remark:'',
                  rupees: ''
                }]
                self.representative=null
                self.documentModule=null
                self.$bvModal.hide('modal-select-details')

                self.creatingAccount=false
              }
            })
            .catch(error=>{
              self.payments=[{
                  paymentReceivedDate:'',
                  modeOfPayment: 'Cash',
                  bankName: '',
                  chequeNo:'',
                  remark:'',
                  rupees: ''
              }]
              self.$root.$emit('showToastMessage',error.message,'danger')
              self.selectedUser=''
              self.representative=null
              self.documentModule=null
              console.log(error)
              self.creatingAccount=false
            })
          })
        }
        else
        {
          e.preventDefault()
          self.creatingAccount=false
        }
      });
    },
    closeInquiryFunction(data)
    {
      var self = this

      self.closeInquiry(data,(res,message)=>{
        if(res)
        {
          self.$root.$emit('showToastMessage','Inquiry closed successfully.','success')
        }
        else
        {
          self.$root.$emit('showToastMessage',message,'danger')
        }
      })
    }
  },
  beforeDestroy()
  {
    this.$nextTick(()=>{
      this.$root.$emit('showDeletedInquiries',false)
      this.showDeleted = false
      this.$root.$off('showDeletedInquiries')
      this.$root.$off('hideDeletedInquiries')
      this.StudentInquiry = [];
    })
    // console.log('COMPONENT UNMOUNTED SUCCESSFULLT FROM INQURY LIST');
    // this.fetchMoreDataStop = true;
  }
}
</script>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
