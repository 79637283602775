import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import {dbCollections} from "@/utils/firebaseCollection"
import { getCollectionData } from '@/utils/dynamicGetDataQuery'
import { getCollectionDataTypesense } from '@/utils/Typesense/queries'
import firebaseTimeStamp from 'firebase'
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
import formatPhoneNumber from '@/utils/phoneNumberFormat.js';
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)
  // const refUserListTable = null

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'date_of_inquiry', sortable: true },
    { key: 'student_name', sortable: true },
    { key: 'phone_no', label: 'Phone No.', sortable: false },
    { key: 'education', sortable: false , label: 'Degree'},
    { key: 'branchfiedlstream', sortable: false,label: 'Branch/Field/Stream'},
    { key: 'board_university',label: 'Board/University Name', sortable: false },
    
    // { key: 'email', sortable: false },
    // { key: 'role', sortable: false },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const isSpinner = ref(null)
  const showDeleted = ref(false)

  const intake = ref(null)
  const yearFilter = ref(null)
  const sortContect  = ref(null)
  const timeFnction  = ref(null)

  //New
  const StudentInquiry = ref([])
  const isFetching = ref(false)
  const previuosDisabled = ref(false)
  const nextDisabled = ref(false)
  const firstDoc = ref(null)
  const lastDoc = ref(null)
  const docCategoryTable  = ref(null)
  const fetchMoreDataStop = ref(false)

  const dataMeta = computed(() => {
    // const localItemsCount = refUserListTable ? refUserListTable.localItems.length : 0
    const localItemsCount = docCategoryTable.value ? docCategoryTable.value.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })
  const refetchData = () => {
    refUserListTable.value.refresh()
    // refUserListTable.refresh()
  }
  
  // const rereshUsers = function(callback){
  //   refetchData()
  //   callback(true)
  // }
  
  watch([searchQuery, roleFilter, planFilter], () => {
    refetchData()
  })

  watch([perPage,intake,yearFilter,showDeleted],()=>{
    fetchStudentsFromTypesense(true,null,()=>refetchData());
  })
  
  isSpinner.value=true
  function fetchStudentsFromTypesense(resetResult = true,limitData=null,callback){
    try {
        if (resetResult) {
            currentPage.value = 1;
            totalUsers.value = 0;
        }

        let searchParameters = {
            collectionName: `${dbCollections.INQUIRY}`,
            search: {
                q:"*",
                page: currentPage.value,
                per_page: perPage.value,
            }
        }

        if(limitData !== null) {
          searchParameters.search.limit = limitData.limit;
          searchParameters.search.page = limitData.pageNumber;
        }

        let filter = `${showDeleted.value === false ? 'accountCreated:=false && isDeleted:=false' :'accountCreated:=false && isDeleted:=true' }`

        if (intake.value !== null && intake.value !== '') {
            if(filter !== '') {
                filter += ` && `
            }
            filter += `intake:=${intake.value}`
        }
        if (yearFilter.value !== null && yearFilter.value !== '') {
            if(filter !== '') {
                filter += ` && `
            }
            filter += `year:=${yearFilter.value}`
        }

        if(filter !== '' && filter !== null) {
          searchParameters.search.filter_by = filter;
        }
        if (sortContect.value) {
            searchParameters.search.sort_by = `${(sortContect.value.sortBy && sortContect.value.sortBy !== '') ? `${sortContect.value.sortBy}: ${sortContect.value.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
        }
        if(searchQuery.value !== '' && searchQuery.value !== null) {
          searchParameters.search.q = searchQuery.value;
          searchParameters.search.query_by = 'name';
        }


        getCollectionDataTypesense(searchParameters).then((result)=>{
              if(result.hits.length) {
                if(limitData == null) {
                  totalUsers.value = result.found
                }
                const arrayData = result.hits.map((x) => {
                  const studentObject = x.document;
                  var year = 0
                  var eduData = ''

                  studentObject?.educationDetails.forEach(ed=>{
                    if(ed.yearOfPassing>=year)
                    {
                      year = ed.yearOfPassing
                      eduData=ed
                    }
                  })
              
                  return {
                      'createdInSeconds': studentObject.createdAt,
                      'id':studentObject.id,
                      'isDeleted':studentObject.isDeleted||false,
                      'date_of_inquiry':`${new Date(studentObject.createdAt*1000).getDate()} ${months[new Date(studentObject.createdAt*1000).getMonth()]}, ${new Date(studentObject.createdAt*1000).getFullYear()}`,
                      'student_name':studentObject.name,
                      'phone_no':`${studentObject.countryPhoneCode ? studentObject.countryPhoneCode.dialCode : '+91'} `+(studentObject.countryPhoneCode ? formatPhoneNumber(studentObject.mobileNumber,studentObject.countryPhoneCode.mask) : formatPhoneNumber(studentObject.mobileNumber,'99999 99999')),
                      'intake':studentObject.intake,
                      'year':studentObject.year,
                      'education':eduData.education,
                      'branchfiedlstream' : eduData.branchOrStreamOrFieldName ? eduData.branchOrStreamOrFieldName : '-',
                      'board_university':eduData.boardUniversityName,
                      'docData':studentObject
                  };
              });
              
              if(limitData == null){
                StudentInquiry.value = arrayData;
                isSpinner.value = false;
                callback(arrayData);
              } else {
                StudentInquiry.value = [...StudentInquiry.value,...arrayData];
                isSpinner.value = false;
                callback(arrayData);
              }
            } else {
              if(limitData == null) {
                StudentInquiry.value = []
                isSpinner.value = false;
                totalUsers.value = 0;
                callback([]);
              }
            }
        })
    } catch (error) {
        isSpinner.value = false;
        console.error(error)
        callback([]);
    }
  }
  function handleSortChange(context) {
      let sortFieldObject = {
          date_of_inquiry : "createdAt",
          student_name : "name",
          phone_no:'',
          board_university : "",
          education : "", 
          branchfiedlstream : "", 
          action : "", 
      }
      const {sortBy,sortDesc} = context
      sortContect.value = {sortBy:sortFieldObject[sortBy],sortDesc};
      fetchStudentsFromTypesense(false,null,()=>{refetchData()})
  }
  function handleSearch() {
    clearTimeout(timeFnction.value);
    timeFnction.value = setTimeout(() => {
      fetchStudentsFromTypesense(false,null,()=>{refetchData()});
    }, 1000);
  }
  function handlePageChange(newPage) {
      currentPage.value = newPage;
      fetchStudentsFromTypesense(false,null,()=>{refetchData()})
  }
  function fetchStudentInquiry(direction = '',refetchData = false) {
    try {
        if(refetchData == false) {
          // console.log('fetchCollege START',new Date().getTime());
          isSpinner.value = true
          isFetching.value = true;
          StudentInquiry.value = []
        }
        let whereQueries = [
          {
            field: "accountCreated",
            operation: "==",
            value: false
          },
        ]

        let limit = 100
        let orderByValue = {field: "createdAt",value: 1}
        let refDoc;
        let refDoc1;
        let limitToLast = 0;
        if(direction == 'next') {
            orderByValue = {field: "createdAt",value: 1}
            refDoc = {
                doc: lastDoc.value
            }
        } else if(direction == 'previous') {
            orderByValue = {field: "createdAt",value: 1}
            refDoc1 = {
              doc: firstDoc.value
            }
            limitToLast = limit
        }
        getCollectionData(`${dbCollections.INQUIRY}`,whereQueries, orderByValue,limit,'','',refDoc?.doc || '',refDoc1?.doc || '',limitToLast)
          .then((querySnapshot) => {

            if(querySnapshot.empty == true) {
              isSpinner.value = false;
              isFetching.value = false;
              if(direction == 'next') {
                nextDisabled.value = true;
                lastDoc.value = '';
              } else if(direction == 'previous') {
                previuosDisabled.value = true;
                firstDoc.value = '';
              }
              return;
            }
            // StudentInquiry.value = [];
            const docs =  querySnapshot.docs?.map((x) => ({...x.data(), id: x.id})).filter((e)=>e.accountCreated == false);
            if(direction == 'next') {
              lastDoc.value = querySnapshot.docs?.[querySnapshot.docs.length - 1];
            } else if(direction == 'previous') {
              firstDoc.value = querySnapshot.docs?.[0];
            }
            let users = [];
            docs.forEach(async(doc)=>{
                var year = 0
                var eduData = ''

                doc.educationDetails.forEach(ed=>{
                  if(ed.yearOfPassing>=year)
                  {
                    year = ed.yearOfPassing
                    eduData=ed
                  }
                })
                const userDetail = {
                  'id':doc.id,
                  'isDeleted':doc.isDeleted||false,
                  'date_of_inquiry':`${new Date(doc.createdAt.seconds*1000).getDate()} ${months[new Date(doc.createdAt.seconds*1000).getMonth()]}, ${new Date(doc.createdAt.seconds*1000).getFullYear()}`,
                  'student_name':doc.name,
                  'phone_no':'+91 '+doc.mobileNumber,
                  'intake':doc.intake,
                  'year':doc.year,
                  'education':eduData.education,
                  'branchfiedlstream' : eduData.branchOrStreamOrFieldName ? eduData.branchOrStreamOrFieldName : '-',
                  'board_university':eduData.boardUniversityName,
                  'docData':doc
                };
                users.push(userDetail)
                if(users.length === docs.length) {
                  // if(refetchData == false) {
                  //   console.log('fetchStudentInquiry END',new Date().getTime());
                  // }
                  StudentInquiry.value = [...StudentInquiry.value,...users]
                  lastDoc.value = querySnapshot.docs[querySnapshot.docs.length - 1];
                  firstDoc.value = querySnapshot.docs[0];
                  totalUsers.value = StudentInquiry.value.length
                  isSpinner.value = false;
                  isFetching.value = false;
                  if (nextDisabled.value === false && fetchMoreDataStop.value === false) {
                    fetchStudentInquiry('next',true);
                  }
                }
              })
          })
          .catch((error) => {
              isFetching.value = false;
              isSpinner.value = false;
              console.error("Error in fetchStudentInquiry", error);
          });
    } catch (error) {
        isFetching.value = false;
        console.error("Error in fetchStudentInquiry", error);
    }
  }
  const filteredInquiryList = computed(()=>{
    var finalArr = StudentInquiry.value;
    var tmp = [];

    if(searchQuery.value!='')
    {
      finalArr.forEach(UD=>{
        if(
          searchQuery.value && UD?.student_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          searchQuery.value && UD?.board_university.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          searchQuery.value && UD?.education.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          searchQuery.value && UD?.phone_no.toString().includes(searchQuery.value) ||
          searchQuery.value && UD?.date_of_inquiry.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
        {
          tmp.push(UD)
        }
      })
      finalArr=tmp
      tmp=[]
    }
    //Intake Filter
    if(intake.value!=null)
    {
      finalArr.forEach(UD=>{
        if(UD.intake == intake.value)
        { 
          tmp.push(UD)
        }
      })
      finalArr=tmp
      tmp=[]
    }
    //Year Filter
    if(yearFilter.value!=null)
    {
      finalArr.forEach(UD=>{
        if(UD.year == yearFilter.value)
        { 
          tmp.push(UD)
        }
      })
      finalArr=tmp
      tmp=[]
    }


    //Managing Show CLose and Open Inquiry
    if(showDeleted.value) {
      finalArr.forEach(UD=>{
        if(UD.isDeleted)
        { 
          tmp.push(UD)
        }
      })
      finalArr=tmp
      tmp=[]
    } else {
      finalArr.forEach(UD=>{
        if(!UD.isDeleted)
        { 
          tmp.push(UD)
        }
      })
      finalArr=tmp
      tmp=[]
    }

    //Managing Sorting
    if(isSortDirDesc.value==true)
      {
        if(sortBy.value=='student_name')
        {
          finalArr = finalArr.sort((a, b) => (a.student_name > b.student_name ? 1 : -1))//Asc
        }
        else if(sortBy.value=='phone_no')
        {
          finalArr = finalArr.sort((a, b) => (a.mobileNumber > b.mobileNumber ? 1 : -1))//Asc
        }
        else if(sortBy.value=='education')
        {
          finalArr = finalArr.sort((a, b) => (a.education > b.education ? 1 : -1))//Asc
        }
        else if(sortBy.value=='branchfiedlstream')
        {
          finalArr = finalArr.sort((a, b) => (a.branchfiedlstream > b.branchfiedlstream ? 1 : -1))//Asc
        }
        else if(sortBy.value=='board_university')
        {
          finalArr = finalArr.sort((a, b) => (a.boardUniversityName > b.boardUniversityName ? 1 : -1))//Asc
        }
        else if(sortBy.value=='date_of_inquiry')
        {
          finalArr = finalArr.sort((a, b) => (a.docData.createdAt > b.docData.createdAt ? 1 : -1))//Asc
        }
      }
      else if(isSortDirDesc.value==true)
      {
        if(sortBy.value=='student_name')
        {
          finalArr = finalArr.sort((a, b) => (a.name > b.name ? -1 : 1))
        }
        else if(sortBy.value=='phone_no')
        {
          finalArr = finalArr.sort((a, b) => (a.mobileNumber > b.mobileNumber ? -1 : 1))
        }
        else if(sortBy.value=='education')
        {
          finalArr = finalArr.sort((a, b) => (a.education > b.education ? -1 : 1))
        }
        else if(sortBy.value=='branchfiedlstream')
        {
          finalArr = finalArr.sort((a, b) => (a.branchfiedlstream > b.branchfiedlstream ? -1 : 1))
        }
        else if(sortBy.value=='board_university')
        {
          finalArr = finalArr.sort((a, b) => (a.boardUniversityName > b.boardUniversityName ? -1 : 1))
        }
        else if(sortBy.value=='date_of_inquiry')
        {
          finalArr = finalArr.sort((a, b) => (a.docData.createdAt > b.docData.createdAt ? -1 : 1))
        }
      }
    return finalArr;
  })
  const refreshUsers = (selectedUser,callback) => {
    let findIndex = StudentInquiry.value.findIndex((e)=>e.id == selectedUser.item.id);
    if(findIndex !== -1) {
      StudentInquiry.value.splice(findIndex,1);
      callback(true)
    } else {
      callback(true)
    }
  }

  const closeInquiry = (ctx, callback)=>{
    var index = (currentPage.value-1) * perPage.value + ctx.index + 1

      db
        .collection(dbCollections.INQUIRY)
        .doc(ctx.item.id)
        .update({
          isDeleted:true,
          updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
        })
        .then(()=>{
          let findIndex = StudentInquiry.value.findIndex((e)=>e.id === ctx.item.id)
          if(findIndex !== -1) {
            StudentInquiry.value.splice(findIndex,1);
            totalUsers.value = totalUsers.value - 1;
            fetchStudentsFromTypesense(false,{limit:1,pageNumber:currentPage.value+1},()=>refetchData());
            callback(true,' success')
          } else {
            callback(true,' success')
          }
        })
        .catch(error=>{
          console.log(error)
          callback(false,error.message)
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    closeInquiry,
    // rereshUsers,  
    refreshUsers,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    isSpinner,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    showDeleted,
    yearFilter,
    intake,
    fetchStudentInquiry,
    filteredInquiryList,
    StudentInquiry,
    nextDisabled,
    isFetching,
    docCategoryTable,
    fetchMoreDataStop,
    fetchStudentsFromTypesense,
    handlePageChange,
    handleSearch,
    handleSortChange
  }
}
